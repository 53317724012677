import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import store from './store'
import moment from 'moment'
import { download } from '@/utils/request'



import '@/assets/theme/index.css';
import '@/assets/styles/index.scss'
import './assets/iconfont/iconfont.css'



Vue.config.productionTip = false
Vue.use(Element)
Vue.prototype.$moment = moment;
Vue.prototype.download = download;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
