<template>
  <div id="app" v-cloak>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  computed: {
    cachedViews() {},
    key() {
      return this.$route.path;
    }
  },
  created() {
    let sidebarIndex = sessionStorage.getItem("sidebarIndex");
    if (sidebarIndex) {
      this.$store.dispatch("sidebar/toggleSideBar", sidebarIndex);
    }
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
}
</style>
