const state = {
  sidebarIndex: 0

}

const mutations = {
  TOGGLE_SIDEBAR: (state, sidebarIndex) => {
    state.sidebarIndex = sidebarIndex
  },
}

const actions = {
  toggleSideBar({ commit }, sidebarIndex) {
    sessionStorage.setItem('sidebarIndex', sidebarIndex)
    commit('TOGGLE_SIDEBAR', sidebarIndex)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}