import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [

  {
    path: '/',
    component: () => import('@/views/index'),
    name: 'Index',
    meta: { title: '首页', icon: 'dashboard', affix: true },
  },
  {
    path: '/search/:searchKey',
    component: () => import('@/views/search'),
    name: 'Search',
    meta: { title: '搜索',subTitle: 'search', icon: 'dashboard', affix: true },
  },
  {
    path: '/news',
    component: () => import('@/views/news'),
    name: 'News',
    hidden: true,
    meta: { title: '新闻动态', subTitle: 'news information', icon: 'dashboard', affix: true },
    redirect: '/news/inner',
    children: [
      {
        path: 'inner',
        component: () => import('@/views/news/inner'),
        name: 'Inner',
        meta: { title: '公司新闻', icon: 'dashboard', affix: true },

      },
      {
        path: 'inner/detail/:id',
        component: () => import('@/views/news/inner/detail'),
        name: 'innerDetail',
        meta: { title: '公司新闻详情', icon: 'dashboard', affix: true },
      },
      {
        path: 'business',
        component: () => import('@/views/news/business'),
        name: 'Business',
        meta: { title: '行业动态', icon: 'dashboard', affix: true }
      },
      {
        path: 'business/detail/:id',
        component: () => import('@/views/news/business/detail'),
        name: 'businessDetail',
        meta: { title: '行业动态详情', icon: 'dashboard', affix: true },
      },

    ]
  },
  {
    path: '/bid',
    component: () => import('@/views/bid'),
    name: 'Bid',
    meta: { title: '招采中心', subTitle: 'tendering centre', icon: 'dashboard', affix: true },
    redirect: '/bid/tender',
    children: [
      {
        path: 'tender',
        component: () => import('@/views/bid/tender'),
        name: 'Tender',
        meta: { title: '招标公告', icon: 'dashboard', affix: true }
      },
      {
        path: 'tender/detail/:id',
        component: () => import('@/views/bid/tender/detail'),
        name: 'tenderDetail',
        meta: { title: '招标公告详情', icon: 'dashboard', affix: true },
      },
      {
        path: 'successful',
        component: () => import('@/views/bid/successful'),
        name: 'Successful',
        meta: { title: '中标公告', icon: 'dashboard', affix: true }
      },
      {
        path: 'successful/detail/:id',
        component: () => import('@/views/bid/successful/detail'),
        name: 'successfulDetail',
        meta: { title: '中标公告详情', icon: 'dashboard', affix: true },
      },
      {
        path: 'corr',
        component: () => import('@/views/bid/corr'),
        name: 'Corr',
        meta: { title: '终止公告', icon: 'dashboard', affix: true }
      },
      {
        path: 'corr/detail/:id',
        component: () => import('@/views/bid/corr/detail'),
        name: 'corrDetail',
        meta: { title: '终止公告详情', icon: 'dashboard', affix: true },
      },
    ]
  },
  {
    path: '/case',
    component: () => import('@/views/case'),
    name: 'Case',
    meta: { title: '经典案例', subTitle: 'classic case', icon: 'dashboard', affix: true },
    redirect: '/case/agency',
    children: [
      {
        path: 'agency',
        component: () => import('@/views/case/agency'),
        name: 'Agency',
        meta: { title: '招标代理', icon: 'dashboard', affix: true }
      },
      {
        path: 'cost',
        component: () => import('@/views/case/cost'),
        name: 'Cost',
        meta: { title: '造价咨询', icon: 'dashboard', affix: true }
      },
      {
        path: 'agency/detail/:id',
        component: () => import('@/views/case/agency/detail'),
        name: 'agencyDetail',
        meta: { title: '招标代理详情', icon: 'dashboard', affix: true },
      },
      {
        path: 'cost/detail/:id',
        component: () => import('@/views/case/cost/detail'),
        name: 'costDetail',
        meta: { title: '造价咨询详情', icon: 'dashboard', affix: true },
      },
    ]
  },
  {
    path: '/contact',
    component: () => import('@/views/contact'),
    name: 'Contact',
    meta: { title: '关于我们', subTitle: 'contact us', icon: 'dashboard', affix: true },
    redirect: '/contact/introduction',
    children: [
      {
        path: 'introduction',
        component: () => import('@/views/contact/introduction'),
        name: 'Introduction',
        meta: { title: '公司简介', icon: 'dashboard', affix: true }
      },
      {
        path: 'information',
        component: () => import('@/views/contact/information'),
        name: 'Information',
        meta: { title: '联系我们', icon: 'dashboard', affix: true }
      },
    ]
  },

  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },

]


// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  base: '/',
  mode: 'history', // 去掉url中的#
  // mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})